import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useGetProjectListMutation } from "../services/apis";
import moment from "moment";
import IMG_1 from "../assets/images/icons/edit.svg";
import IMG_2 from "../assets/images/icons/mentors.png";

import { PAGE_LIMIT } from "../utils/constants";
import Spinner from "../components/Spinner";
import NoData from "../components/NoData";

import { useContractByIdQuery } from "../services/apis";

const ViewProject = () => {
  const { id } = useParams();

  const [newData, setNewData] = useState([]);
  const [search, setSearch] = useState("");

  const [searchTimeout, setSearchTimeout] = useState(null);

  const [getProjectList, { data, isLoading, error }] =
    useGetProjectListMutation();

  const { data: contractData, isFetching, refetch } = useContractByIdQuery(id);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout); // Clear the previous timeout
    }

    const timeoutId = setTimeout(() => {
      const obj = {
        id,
      };
      if (search !== "") {
        obj.search = search;
      }
      getProjectList(obj);
    }, 500);

    setSearchTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    setNewData(data?.data?.data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);


  return (
    <>
      <div className="content-wrapper">
        <div className="row mx-n3">
          <div className="col-md-12 stretch-card grid-margin px-3">
            <div className="box-1 row mx-0">
              <div className="col-md-3 mb-3 border-right border-bottom">
                <h6>{contractData?.data?.contractName}</h6>
              </div>
              <div className="col-md-9 mb-3 border-bottom">
                <p className="mb-0 fs-13 line-clamp-3">
                  {contractData?.data?.description}
                </p>
              </div>
              <div className="col-md-3 mb-3 border-right">
                <div className="d-flex align-items-center">
                  <div className="wh-20 img mr-3">
                    <img src={IMG_2} alt className="img-full" />
                  </div>
                  <div className="pro-content w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0 fs-12">
                        {contractData?.data?.epPM?.name}
                      </h6>
                    
                    </div>
                    <small className="fs-10 text-grey">
                      {contractData?.data?.epPM?.email}
                    </small>
                  </div>
                </div>
                <small className="fw-bold text-primary mt-2 d-block">
                  EP Project Manager
                </small>
              </div>

              <div className="col-md-3 mb-3 border-right">
                <div className="d-flex align-items-center">
                  <div className="wh-20 img mr-3">
                    <img src={IMG_2} alt className="img-full" />
                  </div>
                  <div className="pro-content w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0 fs-12">
                        {contractData?.data?.epRM?.name}
                      </h6>
                      
                    </div>
                    <small className="fs-10 text-grey">
                      {contractData?.data?.epRM?.email}
                    </small>
                  </div>
                </div>
                <small className="fw-bold text-primary mt-2 d-block">
                  EP Risk Manager
                </small>
              </div>

              <div className="col-md-3 mb-3 border-right">
                <div className="d-flex align-items-center">
                  <div className="wh-20 img mr-3">
                    <img src={IMG_2} alt className="img-full" />
                  </div>
                  <div className="pro-content w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0 fs-12">
                        {contractData?.data?.clientPM?.name}
                      </h6>
                      {/* <a href="#" className="wh-14">
                        <img src={IMG_1} alt className="img-full" />
                      </a> */}
                    </div>
                    <small className="fs-10 text-grey">
                      {contractData?.data?.clientPM?.email}
                    </small>
                  </div>
                </div>
                <small className="fw-bold text-primary mt-2 d-block">
                  Client Project Manager
                </small>
              </div>

              <div className="col-md-3 mb-3 border-right">
                <div className="d-flex align-items-center">
                  <div className="wh-20 img mr-3">
                    <img src={IMG_2} alt className="img-full" />
                  </div>
                  <div className="pro-content w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mb-0 fs-12">
                        {contractData?.data?.clientRM?.name}
                      </h6>
                      {/* <a href="#" className="wh-14">
                        <img src={IMG_1} alt className="img-full" />
                      </a> */}
                    </div>
                    <small className="fs-10 text-grey">
                      {contractData?.data?.clientRM?.email}
                    </small>
                  </div>
                </div>
                <small className="fw-bold text-primary mt-2 d-block">
                  Client Risk Manager
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-table round">
          <div className="row align-items-center mx-0">
            <div className="col-md-6">
              <div className="form-group my-2 mx-1 px-0 col-md-6">
                <input
                  type="search"
                  className="form-control font-300 rounded-pill lg font-16"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="search"
                />
              </div>
            </div>
            {/* <div className="col-md-6 text-right">
              <a href className="btn-outline-primary btn rounded-pill">
                Create New
              </a>
            </div> */}
          </div>
          <div className="table-responsive">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <table className="table table-striped table-borderless text-black mb-0">
                  {newData?.length === 0 ? (
                    <NoData />
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th className="py-4" width={50}>
                            EP Project No.
                          </th>
                          <th className="py-4">Project Name</th>
                          <th className="py-4">Description</th>
                          <th className="py-4">EP Project Manager</th>
                          <th className="py-4">EP Risk Manager</th>
                          <th className="py-4">Client Project Manager</th>
                          <th className="py-4">Client Risk Manager</th>
                          <th className="py-4">Start Date</th>
                          <th className="py-4">End Date</th>
                          {/* <th className="py-4" width={125}>
                            Action
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!!newData?.length &&
                          newData?.map((item, index) => {
                            return (
                              <tr key={item?._id}>
                                <td>{index + 1}</td>
                                <td>{item?.projectName?.slice(0, 25)}</td>
                                <td>{item?.description?.slice(0, 25)}</td>
                                <td>{item?.epPM?.name}</td>
                                <td>Joseph</td>
                                <td>{item?.clientPM?.name}</td>
                                <td>{item?.clientRM?.name}</td>
                                <td>
                                  {moment(item?.startDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.endDate).format("DD MMM YYYY")}
                                </td>
                                {/* <td>
                                  <div className="action">
                                    <a href="edit-risk.php" className="mr-4">
                                      <i className="mdi mdi-pencil" />
                                    </a>
                                  </div>
                                </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </>
                  )}
                </table>
              </>
            )}
          </div>
        </div>
        {/* Pagination */}
      </div>
    </>
  );
};

export default ViewProject;
