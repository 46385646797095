import React from "react";

const ViewCms = ({ type }) => {
  return (
    <>
      <div className="tab-pane fade tab-2">
        <div>
          <div className="shadow-md rounded-lg mb-2">
            <div className="p-3 position-relative">
              <label className="size-12 font-md">Question</label>
              <input
                type="text"
                className="form-control lg br-18 mb-3"
                value={type?.heading}
                disabled
              />
              <label className="size-12 font-md">Answer</label>
              <textarea
                className="form-control lg br-18 bg-white py-4 h-auto bg-transparent"
                rows={1}
                disabled
                value={type?.description}
              />
              <div className="action faq-actions">
                <a className="mr-4" title="Edit">
                  <img src="assets/images/icons/edit.svg" />
                </a>
                <a className="mr-4">
                  <img src="assets/images/icons/trash.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tab-pane fade show active tab-2">
        {type?.length === 0 ? (
          <div style={{ textAlign: "center", padding: "3em 1em" }}>
            No data post yet.
          </div>
        ) : (
          <div className="shadow-md rounded-lg mb-2">
            <div className="p-3">
              <label className="size-12 font-md">Heading</label>
              <input
                type="text"
                className="form-control lg br-18 mb-3"
                value={type?.heading}
                disabled
              />
              <label className="size-12 font-md">Description</label>
              <textarea
                className="form-control lg br-18 bg-white py-4 h-auto bg-transparent"
                rows={13}
                disabled
                value={type?.description}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewCms;
