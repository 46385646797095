import React, { useState, useEffect } from "react";
import ViewCms from "./CMS/ViewCms";
import Faq from "./CMS/Faq";
import {
  useGetCmsQuery,
  useDeleteFaqMutation,
  useUpdateCmsMutation,
} from "../services/apis";
import ServerErr from "../components/ServerErr";
import { useLocation } from "react-router-dom";
import { PopUp } from "../utils/alert";

const Cms = () => {
  const { state } = useLocation();
  const [type, setType] = useState(0);
  const [addFaqStatus, setAddFaqStatus] = useState(false);
  const [deleteFaq, { data: delData }] = useDeleteFaqMutation();
  const [editFaq, setEditFaq] = useState(true);
  const [editId, setEditId] = useState("");
  const [faqData, setFaqData] = useState([]);

  const [ques, setQues] = useState("");
  const [answer, setAnswer] = useState("");

  const { data, refetch, isError, error } = useGetCmsQuery(type);
  const [updateCms, { data: updateData }] = useUpdateCmsMutation();

  useEffect(() => {
    refetch();
  }, [type]);

  const handleDelete = (id) => {
    deleteFaq({
      id,
    });
  };

  useEffect(() => {
    if (delData?.success) {
      PopUp("Faq deleted successfully", "", "success");
      refetch();
    }
  }, [delData]);

  useEffect(() => {
    setQues(data?.data[0]?.heading);
    setAnswer(data?.data[0]?.description);
  }, [data]);

  useEffect(() => {
    setFaqData(
      data?.data.map((item) => ({
        ques: item.heading,
        answer: item.description,
      }))
    );
  }, [data]);

  const handleQuesChange = (value, index) => {
    const newData = [...faqData];

    newData[index] = {
      ...newData[index],
      ques: value,
    };

    setFaqData(newData);
  };

  const handleAnswerChange = (value, index) => {
    const newData = [...faqData];

    newData[index] = {
      ...newData[index],
      answer: value,
    };

    setFaqData(newData);
  };

  const handleUpdateFaq = (id, idx) => {
    const filteredArray = faqData.filter((element, index) => index === idx);

    updateCms({
      id,
      heading: filteredArray[0]?.ques,
      description: filteredArray[0]?.answer,
    });
  };

  useEffect(() => {
    if (updateData?.success) {
      PopUp("Faq updated successfully", "", "success");
      refetch();
    }
  }, [updateData]);

  if (isError) {
    return <ServerErr err={error?.data} />;
  }

  return (
    <>
      <div>
        <div className="content-wrapper">
          <div className="search-bar d-flex align-items-center justify-content-between mb-4">
            <div className="filter-tabs">
              <ul className="nav nav-tabs weight-300 targetClass">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href=".tab-2"
                    data-target=".tab-2"
                    onClick={() => {
                      setType(0);
                      setAddFaqStatus(false);
                    }}
                  >
                    FAQ's
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href=".tab-2"
                    data-target=".tab-3"
                    onClick={() => {
                      setType(1);
                      setAddFaqStatus(false);
                    }}
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href=".tab-2"
                    data-target=".tab-4"
                    onClick={() => {
                      setType(2);
                      setAddFaqStatus(false);
                    }}
                  >
                    Legals
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href=".tab-2"
                    data-target=".tab-5"
                    onClick={() => {
                      setType(3);
                      setAddFaqStatus(false);
                    }}
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active tab-2">
                <div className="search pl-md-4 ml-md-2 pt-1">
                  {" "}
                  {data?.data?.length === 0 ? (
                    <>
                      {state?.data?.add && (
                        <a
                          className="btn btn-primary rounded-pill"
                          onClick={() => setAddFaqStatus(true)}
                        >
                          Add FAQ's
                        </a>
                      )}
                    </>
                  ) : (
                    <>
                      {state?.data?.add && (
                        <a
                          className="btn btn-primary rounded-pill"
                          onClick={() => setAddFaqStatus(true)}
                        >
                          Add FAQ's
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="tab-pane fade tab-3">
                <div className="search pl-md-4 ml-md-2 pt-1">
                  {data?.data?.length === 0 ? (
                    <>
                      {state?.data?.add && (
                        <a
                          className="btn btn-primary rounded-pill"
                          onClick={() => setAddFaqStatus(true)}
                        >
                          Add Terms &amp; Conditions
                        </a>
                      )}
                    </>
                  ) : (
                    <>
                      {state?.data?.edit && (
                        <a
                          onClick={() => setAddFaqStatus(true)}
                          className="btn btn-secondary rounded-pill"
                        >
                          Edit
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="tab-pane fade tab-4">
                <div className="search pl-md-4 ml-md-2 pt-1">
                  {" "}
                  {data?.data?.length === 0 ? (
                    <>
                      {state?.data?.add && (
                        <a
                          className="btn btn-primary rounded-pill"
                          onClick={() => setAddFaqStatus(true)}
                        >
                          Add Legals
                        </a>
                      )}
                    </>
                  ) : (
                    <>
                      {state?.data?.edit && (
                        <a
                          className="btn btn-secondary rounded-pill"
                          onClick={() => setAddFaqStatus(true)}
                        >
                          Edit Legals
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="tab-pane fade tab-5">
                <div className="search pl-md-4 ml-md-2 pt-1">
                  {" "}
                  {data?.data?.length === 0 ? (
                    <>
                      {state?.data?.add && (
                        <a
                          className="btn btn-primary rounded-pill"
                          onClick={() => setAddFaqStatus(true)}
                        >
                          Add Privacy Policy
                        </a>
                      )}
                    </>
                  ) : (
                    <>
                      {state?.data?.edit && (
                        <a
                          className="btn btn-secondary rounded-pill"
                          onClick={() => setAddFaqStatus(true)}
                        >
                          Edit
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card card-table round">
            <div className="tab-content">
              {addFaqStatus ? (
                <Faq
                  type={type}
                  getData={data?.data}
                  status={() => setAddFaqStatus(false)}
                />
              ) : (
                <>
                  {type === 0 ? (
                    <div className="tab-pane fade show active tab-2">
                      <div>
                        <div className="shadow-md rounded-lg mb-2">
                          {data?.data?.length > 0 &&
                            data?.data?.map((item, index) => {
                              const currentQues =
                                (faqData?.length > 0 && faqData[index]?.ques) ||
                                "";
                              const currentAnswer =
                                (faqData?.length > 0 &&
                                  faqData[index]?.answer) ||
                                "";
                              return (
                                <div
                                  className="p-3 position-relative"
                                  key={item._id}
                                >
                                  <label
                                    className="size-12 font-md"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Question {index + 1}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control lg br-18 mb-3"
                                    value={currentQues}
                                    onChange={(e) =>
                                      editId === item?._id &&
                                      handleQuesChange(e.target.value, index)
                                    }
                                    disabled={editFaq}
                                  />
                                  <label
                                    className="size-12 font-md"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Answer
                                  </label>
                                  <textarea
                                    className="form-control lg br-18 bg-white py-4 h-auto bg-transparent"
                                    rows={4}
                                    disabled={editFaq}
                                    onChange={(e) =>
                                      editId === item?._id &&
                                      handleAnswerChange(e.target.value, index)
                                    }
                                    value={currentAnswer}
                                  />
                                  <div className="action faq-actions">
                                    {editId === item?._id ? (
                                      <>
                                        {" "}
                                        <button
                                          className="btn btn-primary"
                                          style={{
                                            marginTop: "-10px",
                                            padding: ".5em 1em",
                                          }}
                                          onClick={() =>
                                            handleUpdateFaq(item?._id, index)
                                          }
                                        >
                                          Submit
                                        </button>
                                        <button
                                          className="btn btn-secondary"
                                          onClick={() => setEditId("")}
                                          style={{
                                            marginTop: "-10px",
                                            marginLeft: "1em",
                                            padding: ".43em 1em",
                                            color: "black",
                                          }}
                                        >
                                          Cancel
                                        </button>{" "}
                                      </>
                                    ) : (
                                      <>
                                        <a
                                          className="mr-4"
                                          title="Edit"
                                          onClick={() => {
                                            setEditFaq(false);
                                            setEditId(item?._id);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img src="assets/images/icons/edit.svg" />
                                        </a>
                                        <a
                                          className="mr-4"
                                          onClick={() =>
                                            handleDelete(item?._id)
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img src="assets/images/icons/trash.svg" />
                                        </a>
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ViewCms type={data?.data[0]} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cms;
