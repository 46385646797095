import React, { useState, useEffect } from "react";
import { useUserLogMutation } from "../services/apis";
import { useParams } from "react-router-dom";
import moment from "moment";
import NoData from "../components/NoData";
import Pagination from "../components/Pagination";
import { PAGE_LIMIT } from "../utils/constants";

const ViewUser = () => {
  const { id } = useParams();
  const [userLog, { data, isLoading, error }] = useUserLogMutation();

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    userLog({
      id,
      limit: 10,
      page,
    });
  }, [page]);

  useEffect(() => {
    setPageData(data?.data);
  }, []);

  console.log("PAGE", pageData)

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };


  return (
    <>
      <div className="content-wrapper">
        <h4>User Logs</h4>

        <div className="card card-table round mt-4">
          <div className="tab-content">
            <div className="tab-pane fade show active tab-1">
              <div className="table-responsive">
                {data?.data?.data?.length === 0 ? (
                  <NoData />
                ) : (
                  <>
                    <table className="table table-striped table-borderless text-black mb-0">
                      <thead>
                        <tr>
                          <th className="py-4" width={50}>
                            S.N.
                          </th>
                          <th className="py-4">Activity</th>

                          <th className="py-4">Date</th>
                          <th className="py-4">Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {!!data?.data?.data?.length &&
                          data?.data?.data?.map((item, index) => {
                            return (
                              <tr key={item?._id}>
                                <td>{index + 1}</td>
                                <td>{item?.logHistory}</td>
                                <td>
                                  {moment(item?.logDate).format("DD MMM YYYY")}
                                </td>
                                <td>{moment(item?.logDate).format("hh:mm A")}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {pageData?.totalRecords > 10 && (
          <Pagination
            data={pageData}
            pageNo={handlePageChange}
            pageVal={page}
          />
        )}
      </div>
    </>
  );
};

export default ViewUser;
