import React, { useState, useEffect, useRef } from "react";
import { useGetPlatformByIdQuery } from "../services/apis";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { useAddReplyMutation } from "../services/apis";

const ViewPlatform = () => {
  const { id } = useParams();
  const url = window.location.pathname;
  const { data, refetch } = useGetPlatformByIdQuery(id, {
    pollingInterval: 10000,
  });
  const profileId = useSelector(
    (state) => state?.constants?.profileInfo?.data?.adminData?._id
  );

  const [reply, setReply] = useState("");
  const messageListRef = useRef(null);

  // Function to scroll to the bottom of the message list
  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const [
    addReply,
    { data: replyData, isLoading: replyLoading, error: replyErr },
  ] = useAddReplyMutation();

  useEffect(() => {
    refetch();
  }, []);

  const handleReply = () => {
    if (!reply.trim()) {
      return;
    }
    addReply({
      replyTo: data?.data[0]?.userId?._id,
      queryID: id,
      reply,
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [data]);

  useEffect(() => {
    if (replyData?.success) {
      refetch();
      setReply("");
    }
  }, [replyData]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleReply();
    }
  };



  return (
    <>
      <div className="content-wrapper">
        <div className="row mx-n3">
          <div className="col-md-6 stretch-card grid-margin px-3">
            <div className="card card-img-holder text-default">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h4 className="h6 my-0" style={{ fontWeight: "bold" }}>
                    User
                  </h4>
                </div>
                <p className="mb-2">
                  {data?.data[0]?.userId?.name || "Not availabe"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 stretch-card grid-margin px-3">
            <div className="card card-img-holder text-default">
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <h4 className="h6 my-0" style={{ fontWeight: "bold" }}>
                    Query
                  </h4>
                </div>

                <p className="mb-2">{data?.data[0]?.query}</p>
              </div>
            </div>
          </div>
        </div>
        <h4>Reply Threads</h4>
        <div className="row">
          <div className="col-md-12 stretch-card">
            <div className="container">
              <div className="row rounded-lg overflow-hidden">
                <div className="col-12">
                  <div className="py-5 chat-box bg-white" ref={messageListRef}>
                    {!!data?.data[0]?.replyIds?.length &&
                      data?.data[0]?.replyIds?.map((item) => {
                        return (
                          <>
                            {item.replyBy?._id === profileId ? (
                              <div className="media w-50 ml-auto mb-3">
                                <div className="media-body text-right">
                                  <div
                                    className="ml-auto bg-primary rounded py-2 px-3 mb-2"
                                    style={{ width: "max-content" }}
                                  >
                                    <p className="text-small mb-0 text-white">
                                      {item?.reply}
                                    </p>
                                  </div>
                                  <p className="small text-muted">
                                    {moment(item?.replyDate).format("hh:mm")} |{" "}
                                    {moment(item?.replyDate).format("MMM DD")}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="media w-50 mb-3" key={item?._id}>
                                <div className="media-body ml-3">
                                  <div
                                    className="bg-light rounded py-2 px-3 mb-2"
                                    style={{ width: "max-content" }}
                                  >
                                    <p className="text-small mb-0 text-muted">
                                      {item?.reply}
                                    </p>
                                  </div>
                                  <p className="small text-muted">
                                    {moment(item?.replyDate).format("hh:mm")} |{" "}
                                    {moment(item?.replyDate).format("MMM DD")}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "1em 3em",
              width: "90%",
            }}
          >
            <input
              type="text"
              placeholder="Enter your reply..."
              style={{
                width: "100%",
                padding: ".5em 1em",
                background: "#e9e9e9",
                borderRadius: "30px",
                border: "none",
              }}
              onKeyPress={handleKeyPress}
              value={reply}
              onChange={(e) => setReply(e.target.value)}
            />
            <div
              style={{
                background: "green",
                color: "white",
                padding: ".7em 1em",
                borderRadius: "50%",
                marginLeft: "1em",
                cursor: "pointer",
                background: "orange",
              }}
              onClick={handleReply}
            >
              <i class="fa-solid fa-paper-plane"></i>
            </div>

            <div
              style={{
                background: "green",
                color: "white",
                padding: ".7em 1em",
                borderRadius: "50%",
                marginLeft: "1em",
                cursor: "pointer",
                background: "orange",
              }}
              onClick={() => refetch()}
            >
              <i class="fa-solid fa-arrows-rotate"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPlatform;
