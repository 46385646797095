import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetContractListMutation } from "../services/apis";
import NoData from "../components/NoData";
import ServerErr from "../components/ServerErr";
import { useSelector } from "react-redux";
import Pagination from "../components/Pagination";

import { PAGE_LIMIT } from "../utils/constants";

const Projects = () => {
  const urlString = window.location.pathname;

  const adminpPermission = useSelector(
    (state) => state?.constants?.profileData
  );
  const [state, setState] = useState([]);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState({});
  const [search, setSearch] = useState("");
  const [getContractList, { data, isLoading, error, isError }] =
    useGetContractListMutation();

  useEffect(() => {
    const obj = {
      limit: PAGE_LIMIT,
      page,
    };
    if (search !== "") {
      obj.search = search;
    }
    getContractList(obj);
  }, [search, page]);

  const [newData, setNewData] = useState([]);

  useEffect(() => {
    setNewData(data?.data?.data);
    setPageData(data?.data);
  }, [data]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const urlUpdated = urlString.split("/");
    const updatedValue = adminpPermission?.filter(
      (item) => item?.url === urlUpdated[1]
    );
    setState(updatedValue[0]);
  }, [adminpPermission]);

  if (isError) {
    return <ServerErr err={error?.data} />;
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="sub-title d-flex justify-content-between align-items-center">
          <h4 className="weight-500">Contracts</h4>
          <input
            type="text"
            placeholder="search contract"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              border: "1px solid #e3e3e3",
              borderRadius: "10px",
              padding: ".5em 1em",
            }}
          />
        </div>
        <div className="card card-table round mt-4">
          <div className="tab-content">
            {newData?.length === 0 ? (
              <NoData />
            ) : (
              <>
                <div className="tab-pane fade show active tab-1">
                  <div className="table-responsive">
                    <table className="table table-striped table-borderless text-black mb-0">
                      <thead>
                        <tr>
                          <th className="py-4" width={50}>
                            S.N.
                          </th>
                          <th className="py-4">Contract Name</th>
                          <th className="py-4" width={125}>
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {!!newData?.length &&
                          newData?.map((item, index) => {
                            return (
                              <tr key={item?._id}>
                                <td>{index + 1}</td>
                                <td>{item?.contractName}</td>
                                <td>
                                  <div className="action">
                                    {state?.view && (
                                      <Link to={`/view-project/${item?._id}`}>
                                        <a className="mr-4">
                                          <i className="mdi mdi-eye" />
                                        </a>
                                      </Link>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {pageData?.totalRecords > PAGE_LIMIT && (
          <Pagination
            data={pageData}
            pageNo={handlePageChange}
            pageVal={page}
          />
        )}
      </div>
    </>
  );
};

export default Projects;
