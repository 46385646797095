import React from "react";
import LOGIN from "../assets/images/logo.png";

const Inbox = () => {
  return (
    <div className="container-scroller login-bg">
      <div className="container page-body-wrapper full-page-wrapper">
        <div className="row flex-grow">
          <div className="loginLeft col-md-6 d-flex align-items-center pl-md-5">
            <img src="assets/images/login.png" className="ml-md-5" />
          </div>
          <div className="col-lg-6 d-flex align-items-start flex-column justify-content-center">
            <div>
              <div className="loginRight p-5">
                <div className="brand-logo text-center mt-3 mb-4 mb-md-5">
                  <img src={LOGIN} />
                </div>
                <h2 className="text-center mb-4 weight-600">
                  Check your inbox.
                </h2>
                <p>we sent a password reset email</p>
                <div className="mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;
