import { createSlice } from "@reduxjs/toolkit";

export const getConstants = createSlice({
  name: "constants",
  initialState: {
    likeVal: 1,
    impactVal: 1,
    navTitle: "Dashboard",
    profileData: {},
    profileInfo: {},
  },
  reducers: {
    setLikeVal: (state, action) => {
      state.likeVal = action.payload;
    },
    setImpactVal: (state, action) => {
      state.impactVal = action.payload;
    },
    setNavTitle: (state, action) => {
      state.navTitle = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setProfileInfo: (state, action) => {
      state.profileInfo = action.payload;
    },
  },
});

export const {
  setLikeVal,
  setImpactVal,
  setNavTitle,
  setProfileData,
  setProfileInfo,
} = getConstants.actions;

export default getConstants.reducer;
