import React from "react";
import { PAGE_LIMIT } from "../utils/constants";

const Pagination = ({ data, pageNo, pageVal }) => {
  const arrayToMap = new Array(data?.totalPages).fill(null);

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-between mt-3">
        <div className="font-14 weight-500 text-gray">
          {PAGE_LIMIT} Results Per Page
        </div>
        <nav aria-label="Page navigation example">
          <ul className="pagination my-0">
            <li className="page-item" title="Previous">
              <a
                className="page-link px-2"
                role="button"
                onClick={() => pageVal > 1 && pageNo(pageVal - 1)}
              >
                <i className="mdi mdi-chevron-left" />
              </a>
            </li>
            {arrayToMap?.map((item, index) => {
              return (
                <li
                  className={`page-item ${
                    pageVal === index + 1 ? "active" : ""
                  }`}
                  onClick={() => pageNo(index + 1)}
                  style={{ cursor: "pointer" }}
                >
                  <a className="page-link">{index + 1}</a>
                </li>
              );
            })}

            <li className="page-item" title="Next">
              <a
                className="page-link px-2"
                role="button"
                onClick={() =>
                  data?.totalPages > pageVal && pageNo(pageVal + 1)
                }
              >
                <i className="mdi mdi-chevron-right" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Pagination;
