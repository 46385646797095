import React, { useState } from "react";

const ServerErr = ({ err }) => {
  const [status, setStatus] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        height: "85vh",
        display: "grid",
        placeItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ padding: "4em", textAlign: "center" }}>
        <p
          style={{
            fontSize: "17px",
            width: "50%",
            margin: "auto",
            paddingBottom: "2em",
          }}
        >
          Uh-oh! Something's not quite right in our website's building blocks.
          Our tech team is on the case, working to fix the issue. We'll have
          things back up and running soon!
        </p>
        <h2 style={{ color: "#e75d0f" }}>Thanks for your patience!</h2>
        <p onClick={() => setStatus(!status)}>Click here to know more</p>
        {status && (
          <p style={{ color: "red" }}>
           Status Code {err?.code} - {err?.errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default ServerErr;
