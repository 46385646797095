export const PAGE_LIMIT = 10;

export const validateEmail = (input) => {
  // Regular expression pattern for a basic email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Test the input against the email pattern
  return emailPattern.test(input);
};

export const validateFullName = (input) => {
  // Regular expression to check for two words separated by a space
  const fullNamePattern = /^[A-Za-z]+(?:\s[A-Za-z]+)+$/;
  return fullNamePattern.test(input);
};
