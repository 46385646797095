import React, { useState, useEffect } from "react";
import { useAddCmsMutation, useUpdateCmsMutation } from "../../services/apis";
import { PopUp } from "../../utils/alert";
import { errors } from "../../utils/error";

const Faq = ({ type, getData, status }) => {
  const [addCms, { data, error }] = useAddCmsMutation();
  const [updateCms, { data: updateData, error: updateErr }] =
    useUpdateCmsMutation();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = () => {
    if (!title) {
      PopUp("Please add title", "", "error");
      return;
    }
    if (!description) {
      PopUp("Please add description", "", "error");
      return;
    }
    if (getData?.length !== 0 && type !== 0) {
      updateCms({
        id: getData[0]?._id,
        heading: title,
        description,
      });
    } else {
      addCms({
        type,
        heading: title,
        description,
      });
    }
  };

  useEffect(() => {
    setTitle(getData[0]?.heading);
    setDescription(getData[0]?.description);
  }, [getData]);

  useEffect(() => {
    if (data?.success) {
      PopUp("Data added successfully", "", "success");
      window.location.reload();
    }
    errors(error);
  }, [data, error]);

  useEffect(() => {
    if (updateData?.success) {
      PopUp("Data updated successfully", "", "success");
      window.location.reload();
    }
    errors(updateErr);
  }, [updateErr, updateData]);

  return (
    <>
      <div className="tab-pane fade show active tab-1">
        <div className="shadow-md rounded-lg mb-2">
          <div className="p-3">
            <label className="size-12 font-md">Heading</label>
            <input
              type="text"
              className="form-control lg br-18 mb-3"
              placeholder="Rules"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label className="size-12 font-md">Description</label>
            <textarea
              className="form-control lg br-18 bg-white py-4 h-auto bg-white"
              rows={13}
              placeholder="Add Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="px-3 pb-3">
            <a
              href
              className="btn btn-secondary rounded-pill"
              onClick={() => status(false)}
            >
              Cancel
            </a>
            <a
              href
              className="btn btn-primary rounded-pill"
              onClick={handleSubmit}
            >
              Submit
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
