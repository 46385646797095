import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseQuery = fetchBaseQuery({
  baseUrl: "https://epriskbackend.quytech.co/",
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");
    headers.set("Authorization", `Bearer ${token}`);
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result && result?.error?.status === 600) {
    window.location.replace(window.location.origin);
    localStorage.clear();
  }
  return result;
};
export const epRiskApis = createApi({
  reducerPath: "epRiskApis",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "adminAuth/adminLogin",
        method: "POST",
        body: data,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "adminAuth/adminForgotPassword",
        method: "POST",
        body: data,
      }),
    }),
    updateFCM: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `user/updateFcmToken/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    addCms: builder.mutation({
      query: (data) => ({
        url: "admin-cms/createCmsManagement",
        method: "POST",
        body: data,
      }),
    }),

    addUsers: builder.mutation({
      query: (data) => ({
        url: "admin/addUser",
        method: "POST",
        body: data,
      }),
    }),

    addSubAdmin: builder.mutation({
      query: (data) => ({
        url: "permission/addPermission",
        method: "POST",
        body: data,
      }),
    }),

    sendNotification: builder.mutation({
      query: (data) => ({
        url: "admin-notification/sendNotification",
        method: "POST",
        body: data,
      }),
    }),

    getNotification: builder.mutation({
      query: (data) => ({
        url: "admin-notification/adminNotificationList",
        method: "POST",
        body: data,
      }),
    }),

    updateCms: builder.mutation({
      query: (data) => ({
        url: "admin-cms/updateDeatilsCmsManagement",
        method: "PUT",
        body: data,
      }),
    }),

    updateCms: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin-cms/updateDeatilsCmsManagement/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    deleteFaq: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin-cms/deleteCmsManagement/${id}`,
          method: "DELETE",
          body,
        };
      },
    }),

    deleteUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/deleteUser/${id}`,
          method: "DELETE",
          body,
        };
      },
    }),

    deleteSubAdmin: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `permission/deleteSubAdmin/${id}`,
          method: "DELETE",
          body,
        };
      },
    }),

    userLog: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `userLog/getLogById/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getProjectList: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `project/getProjectList/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    updateSubAdmin: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `permission/updatePermission/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    deleteNotification: builder.mutation({
      query: (data) => ({
        url: "admin-notification/deleteNotification",
        method: "DELETE",
        body: data,
      }),
    }),

    blockUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/blockAndUnblockUser/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    editUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/editDeatil/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    resetPassword: builder.mutation({
      query(body) {
        return {
          url: `adminAuth/resetPassword`,
          method: "POST",
          body,
        };
      },
    }),

    platformStatus: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `adminPlatform/updateStatus/${id}`,
          method: "PUT",
          body,
        };
      },
    }),

    setPassword: builder.mutation({
      query(body) {
        return {
          url: `admin/setPassword`,
          method: "POST",
          body,
        };
      },
    }),

    setPasswordAdmin: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/setPassword?id=${id}`,
          method: "POST",
          body,
        };
      },
    }),

    logout: builder.mutation({
      query: (data) => ({
        url: "adminAuth/logout",
        method: "POST",
        body: data,
      }),
    }),

    addReply: builder.mutation({
      query: (data) => ({
        url: "adminPlatform/addReply",
        method: "POST",
        body: data,
      }),
    }),

    getCms: builder.query({
      query: (data) => ({
        url: `admin-cms/getDeatilsCmsManagement/${data}`,
        method: "GET",
      }),
    }),

    getContractList: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `contract/getContractList`,
          method: "POST",
          body,
        };
      },
    }),

    riskTopNReport: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `reports/riskTopNReport/${id}`,
          method: "POST",
          body,
        };
      },
    }),

    getUsers: builder.mutation({
      query: (data) => ({
        url: "admin/getList",
        method: "POST",
        body: data,
      }),
    }),

    getAllList: builder.query({
      query: (data) => ({
        url: `admin/getAllList`,
        method: "GET",
      }),
    }),

    getAllNotifications: builder.query({
      query: (data) => ({
        url: `admin-notification/listNotificationList`,
        method: "GET",
      }),
    }),
    getPlatformList: builder.mutation({
      query: (data) => ({
        url: `adminPlatform/getPlatformList`,
        method: "POST",
        body: data,
      }),
    }),

    getPageList: builder.query({
      query: (data) => ({
        url: `admin-page/getPageList`,
        method: "GET",
      }),
    }),

    getSubAdminList: builder.query({
      query: (data) => ({
        url: `permission/subAdminList?page=${data.page}&limit=${data.limit}`,
        method: "GET",
      }),
    }),

    getPermission: builder.query({
      query: (data) => ({
        url: `permission/getPermission/${data}`,
        method: "GET",
      }),
    }),

    getPlatformById: builder.query({
      query: (data) => ({
        url: `adminPlatform/getPlatformById/${data}`,
        method: "GET",
      }),
    }),

    getDashboard: builder.query({
      query: (data) => ({
        url: `dashboard/getUserCount`,
        method: "GET",
      }),
    }),

    getContract: builder.query({
      query: (data) => ({
        url: `contract/getContractList?page=${data?.page}&limit=${data?.limit}`,
        method: "GET",
      }),
    }),

    getContractProjects: builder.query({
      query: (data) => ({
        url: `project/getProjectList/${data?.id}?page=${data?.page}&limit=${data?.limit}`,
        method: "GET",
      }),
    }),

    contractById: builder.query({
      query: (data) => ({
        url: `contract/getContractById/${data}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRiskTopNReportMutation,
  useForgotPasswordMutation,
  useGetCmsQuery,
  useResetPasswordMutation,
  useUpdateCmsMutation,
  useGetProjectListMutation,
  useAddCmsMutation,
  useAddUsersMutation,
  useGetUsersMutation,
  useDeleteUserMutation,
  useBlockUserMutation,
  useEditUserMutation,
  useSendNotificationMutation,
  useGetAllListQuery,
  useGetAllNotificationsQuery,
  useDeleteNotificationMutation,
  useSetPasswordMutation,
  useGetPlatformListMutation,
  useAddSubAdminMutation,
  useGetPageListQuery,
  useGetSubAdminListQuery,
  useDeleteSubAdminMutation,
  useGetPermissionQuery,
  useUpdateSubAdminMutation,
  useSetPasswordAdminMutation,
  useUserLogMutation,
  useLogoutMutation,
  useAddReplyMutation,
  useGetPlatformByIdQuery,
  useGetDashboardQuery,
  usePlatformStatusMutation,
  useGetContractQuery,
  useGetContractProjectsQuery,
  useGetContractListMutation,
  useUpdateFCMMutation,
  useDeleteFaqMutation,
  useContractByIdQuery,
  useGetNotificationMutation,
} = epRiskApis;
