import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetDashboardQuery } from "../services/apis";

const Dashboard = () => {
  const { data, refetch } = useGetDashboardQuery();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="row mx-n3">
          <div className="col-md-3 stretch-card grid-margin px-3">
            <div className="card card-img-holder text-default" style={{backgroundColor:'#f5f8fb' ,border: "1px solid #e75d0f",borderRadius: "6px"}}>
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <p className="h4 my-0">Total Number of PM</p>
                </div>
                <Link
                  to="/user-management"
                  state={{ value: 0, title: "Add Project Manager" }}
                >
                  <h1 className="weight-800 mb-2">
                    {data?.data?.TotalProjectManager || 0}
                  </h1>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 stretch-card grid-margin px-3">
            <div className="card card-img-holder text-default" style={{backgroundColor:'#f5f8fb' ,border: "1px solid #e75d0f",borderRadius: "6px"}}>
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <p className="h4 my-0">Total Number of RM</p>
                </div>
                <Link
                  to="/user-management"
                  state={{ value: 1, title: "Add Risk Manager" }}
                >
                  <h1 className="weight-800 mb-2">
                    {data?.data?.TotalRiskManager || 0}
                  </h1>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 stretch-card grid-margin px-3">
            <div className="card card-img-holder text-default" style={{backgroundColor:'#f5f8fb' ,border: "1px solid #e75d0f" ,borderRadius: "6px"}}>
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <p className="h4 my-0">Total Number of Engineers</p>
                </div>
                <Link
                  to="/user-management"
                  state={{ value: 2, title: "Add Engineer" }}
                >
                  <h1 className="weight-800 mb-2">
                    {data?.data?.TotalEngineer || 0}
                  </h1>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 stretch-card grid-margin px-3" >
            <div className="card card-img-holder text-default" style={{backgroundColor:'#f5f8fb' ,border: "1px solid #e75d0f" ,borderRadius: "6px"}}>
              <div className="card-body">
                <div className="d-flex align-items-center mb-3">
                  <p className="h4 my-0">Total Number of Contracts</p>
                </div>
                <Link to="/contracts">
                  <h1 className="weight-800 mb-2">
                    {data?.data?.TotalProject || 0}
                  </h1>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
