import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import Loader from "./utils/loader";
import { Navigate, Outlet } from "react-router-dom";
import "./App.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import ViewUser from "./pages/ViewUser";
import Cms from "./pages/Cms";
import SetPassword from "./pages/SetPassword";
import ViewPlatform from "./pages/ViewPlatform";
import SetPasswordSubAdmin from "./pages/SetPasswordSubAdmin";
import Projects from "./pages/Projects";
import ViewProject from "./pages/ViewProject";
import Inbox from "./pages/Inbox";

const LogIn = React.lazy(() => import("./pages/Login"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const Layout = React.lazy(() => import("./components/Layout"));
const Notification = React.lazy(() => import("./pages/Notification"));
const SendNotification = React.lazy(() => import("./pages/SendNotification"));
const PlatformSupport = React.lazy(() => import("./pages/PlatformSupport"));
const ReportGeneration = React.lazy(() => import("./pages/ReportGeneration"));
const Stats = React.lazy(() => import("./pages/Stats"));
const EditRisk = React.lazy(() => import("./pages/EditRisk"));
const SubAdmin = React.lazy(() => import("./pages/SubAdmin"));
const EditSubAdmin = React.lazy(() => import("./pages/EditSubAdmin"));
const AddSubAdmin = React.lazy(() => import("./pages/AddSubAdmin"));
const ViewReport = React.lazy(() => import("./pages/ViewPlatform"));
const ViewUserDetail = React.lazy(() => import("./pages/ViewUserDetail"));
const Welcome = React.lazy(() => import("./pages/Welcome"));
const NoPageFound = React.lazy(() => import("./pages/NoPageFound"));

const User = React.lazy(() => import("./pages/User"));

const PrivateOutlet = () => {
  const token = localStorage.getItem("token") || "";
  return token ? <Outlet /> : <Navigate to="/" />;
};

const LoginRestrict = () => {
  const token = localStorage.getItem("token") || "";
  return !token ? <Outlet /> : <Navigate to="/" />;
};

function App() {
  const adminPermit = useSelector((state) => state?.constants?.profileData);

  const navigate = useNavigate();

  const routes = [
    { path: "/dashboard", element: <Dashboard />, permission: "dashboard" },
    {
      path: "/notification-management",
      element: <Notification />,
      permission: "notification",
    },
    {
      path: "/report-generation",
      element: <ReportGeneration />,
      permission: "report-generation",
    },
    {
      path: "/analytics-and-stats",
      element: <Stats />,
      permission: "analytics-and-stats",
    },
    {
      path: "/sub-admin-management",
      element: <SubAdmin />,
      permission: "sub-admin-management",
    },
    {
      path: "/user-management",
      element: <User />,
      permission: "user-management",
    },
    { path: "/cms-management", element: <Cms />, permission: "cms-management" },
    {
      path: "/notification-management",
      element: <Notification />,
      permission: "notification",
    },
    { path: "/contracts", element: <Projects />, permission: "contract" },
    {
      path: "/platform-support",
      element: <PlatformSupport />,
      permission: "platform-support",
    },
  ];

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              width: "100%",
              display: "grid",
              placeItems: "center",
              height: "100vh",
            }}
          >
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/inbox" element={<Inbox />} />

          <Route
            path="/set-password-subadmin"
            element={<SetPasswordSubAdmin />}
          />

          {/* <Route element={<LoginRestrict />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route
              path="/set-password-subadmin"
              element={<SetPasswordSubAdmin />}
            />
          </Route> */}

          <Route element={<PrivateOutlet />}>
            <Route element={<Layout />}>
              {routes?.map((route, index) => {
                const matchedPermission =
                  adminPermit?.length > 0 &&
                  adminPermit.find((item) => item.url === route.permission);
                  console.log("===================>",adminPermit)
                if (matchedPermission && !matchedPermission.view) {
                  return null;
                }
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                );
              })}

              <Route path="/edit-risk" element={<EditRisk />} />
              <Route path="/edit-sub-admin/:id" element={<EditSubAdmin />} />
              <Route path="/add-sub-admin" element={<AddSubAdmin />} />
              <Route path="/view-user/:id" element={<ViewUser />} />
              <Route path="/view-platform/:id" element={<ViewPlatform />} />
              <Route path="/add-notification" element={<SendNotification />} />
              <Route path="/user-detail" element={<ViewUserDetail />} />
              <Route path="/view-project/:id" element={<ViewProject />} />
              <Route path="/welcome" element={<Welcome />} />
            </Route>
          </Route>
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
