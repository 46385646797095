import React from "react";

const NoData = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        display: "grid",
        placeItems: "center",
        color: "#e75d0f",
      }}
    >
      <h3>There's no information to be found here</h3>
    </div>
  );
};

export default NoData;
